<template>
  <div class="pt-15 pb-3">
    <v-container class="px-0 px-sm-auto">
      <v-row>
        <v-col class="col-6 ml-7 ml-sm-0">
          <v-row
            class="text-color-black font-36 align-center font-weight-600 position-relative title-row"
            :class="{'font-28' : $vuetify.breakpoint.smAndDown}"
          >
            <div class="f-title">Beschreibung des Projekts</div>
            <div class="sec-title">Beschreibung des Projekts</div>
          </v-row>
        </v-col>
      </v-row>
      <v-row style="white-space: pre-line" class="mx-3 mx-sm-0">
        {{project.description}}
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      require: true,
    },
  },
};
</script>