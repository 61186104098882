<template>
  <div class="py-15">
    <v-container>
      <v-row class="mx-2 mx-sm-0">
        <v-img
          :src="
            require('@/assets/images/projects/' + project.folder + '/' + project.images[activeIndex])
          "
          class="img-active"
          :class="{ 'img-active--mobile': $vuetify.breakpoint.xs }"
        />
      </v-row>
      <v-row class="mx-n4 mx-sm-n6" v-if="swiper">
        <swiper
          class="row mx-6 mt-3 mt-sm-6"
          :style="{
          '--swiper-navigation-color': '#000',
        }"
          :options="swiperOption"
        >
          <swiper-slide v-for="(image, index) in project.images" :key="index">
              <v-img
                @click="toggleActive(index)"
                :src="require('@/assets/images/projects/' + project.folder + '/' + image)"
                :class="{
                  'img-small-active': activeIndex == index,
                  'img--mobile': $vuetify.breakpoint.xs,
                }"
                class="img"
              />
          </swiper-slide>
          <div class="swiper-button-prev" v-if="project.images.length > 6" slot="button-prev"></div>
          <div class="swiper-button-next" v-if="project.images.length > 6" slot="button-next"></div>
        </swiper>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    project: {
      require: true,
    },
  },
  data: () => ({
    activeIndex: 0,
    swiper: false,
    swiperOption: {
      slidesPerView: 4,
      spaceBetween: 15,
      slidesPerGroup: 1,
      initialSlide: 0,
      loop: false,
      loopFillGroupWithBlank: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        '960': {
          slidesPerView: 6,
          spaceBetween: 15,
        }
      }
    },
  }),
  mounted() {
    if (this.project.images.length < 6) {
      this.swiperOption.breakpoints['960'].slidesPerView = this.project.images.length
      this.swiper = true
    }
    else {
      this.swiper = true
    }
  },
  methods: {
    toggleActive(index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style scoped lang="scss">
.img {
  height: 160px;
  width: 100%;
  object-fit: cover;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  box-sizing: border-box;
  &--mobile {
    height: 64px !important;
  }

  &-active {
    height: 600px;
    &--mobile {
      height: 340px;
    }
  }
  &-small-active {
    border: 3px solid #f39300;
  }
  &:hover {
    border: 3px solid #f39300;
  }
}
</style>