<template>
  <div>
    <div class="background-main">
      <v-img
        :src="require('@/assets/images/projects/' + project.folder + '/' + project.images[0] )"
        class="image"
        alt="background-img"
      />
      <div class="content">
        <v-container class="px-0">
          <v-row
            align="center"
            class="content-row justify-center"
            :class="{ 'content-row-mobile': $vuetify.breakpoint.smAndDown }"
          >
            <v-row justify="center" class="mt-1 mt-sm-15">
              <v-col
                :class="{ 'font-32': $vuetify.breakpoint.smAndDown }"
                class="col-12 text-center text-color-orange font-48 font-weight-600 text-uppercase"
              >
                {{ project.title }}
              </v-col>
              <v-col
                :class="{ 'font-16': $vuetify.breakpoint.smAndDown }"
                class="col-12 text-center font-20 text-color-white text-uppercase"
              >
                Modernes und funktionelles Rahmenhaus
              </v-col>
              <v-col class="col-12 col-sm-9 mt-sm-13 mt-1 px-8 px-sm-0">
                <v-row
                  justify="center"
                  class="info-card"
                  v-if="!$vuetify.breakpoint.smAndDown"
                  align="center"
                  no-gutters
                >
                  <div class="mr-8">
                    <v-row
                      no-gutter
                      align="center"
                      justify="center"
                      justify-sm="start"
                    >
                      <div>
                        <img src="@/assets/images/gebiet-icon.svg" />
                      </div>
                      <div class="ml-3">
                        <div class="text-color-white">Gebiet:</div>
                        <div class="text-color-orange">
                          {{ project.square }} m<sup>2</sup>
                        </div>
                      </div>
                    </v-row>
                  </div>
                  <div class="mx-8 ml-10">
                    <v-row
                      no-gutters
                      align="center"
                      justify="center"
                      justify-sm="start"
                    >
                      <div>
                        <img src="@/assets/images/kapazitat-icon.svg" />
                      </div>
                      <div class="ml-3">
                        <div class="text-color-white">Kapazität:</div>
                        <div class="text-color-orange">
                          {{ project.capacity }}
                        </div>
                      </div>
                    </v-row>
                  </div>
                  <div class="mx-8 ml-7">
                    <v-row no-gutters align="center">
                      <div>
                        <img src="@/assets/images/anzahi-icon.svg" />
                      </div>
                      <div class="ml-3">
                        <div class="text-color-white">Dachart:</div>
                        <div class="text-color-orange">
                          {{ project.roof_type }}
                        </div>
                      </div>
                    </v-row>
                  </div>
                  <div class="ml-8">
                    <v-row no-gutters align="center">
                      <div>
                        <img src="@/assets/images/dahart-icon.svg" />
                      </div>
                      <div class="ml-3" v-if="project.floors">
                        <div class="text-color-white">
                          Anzahl der Stockwerke:
                        </div>
                        <div class="text-color-orange">2 Etagen</div>
                      </div>
                      <div class="ml-3" v-else>
                        <div class="text-color-white">
                          Anzahl der Schlafzimmer:
                        </div>
                        <div class="text-color-orange">
                          {{ project.bedrooms }}
                        </div>
                      </div>
                    </v-row>
                  </div>
                </v-row>
                <v-row
                  v-else
                  justify="center"
                  align="center"
                  class="info-card-mobile mx-0"
                >
                  <v-col class="col-9">
                    <v-row no-gutters align="center">
                      <div>
                        <img src="@/assets/images/gebiet-icon.svg" />
                      </div>
                      <div class="ml-3">
                        <div class="text-color-white">Gebiet:</div>
                        <div class="text-color-orange">{{ project.square }} m<sup>2</sup></div>
                      </div>
                    </v-row>
                    <v-row no-gutters align="center" class="mt-3">
                      <div>
                        <img src="@/assets/images/kapazitat-icon.svg" />
                      </div>
                      <div class="ml-3">
                        <div class="text-color-white">Kapazität:</div>
                        <div class="text-color-orange">{{ project.capacity }}</div>
                      </div>
                    </v-row>
                    <v-row no-gutters align="center" class="mt-3">
                      <div>
                        <img src="@/assets/images/anzahi-icon.svg" />
                      </div>
                      <div class="ml-3">
                        <div class="text-color-white">Dachart:</div>
                        <div class="text-color-orange">{{ project.roof_type }}</div>
                      </div>
                    </v-row>
                    <v-row no-gutters align="center" class="mt-3" v-if="project.floors">
                      <div>
                        <img src="@/assets/images/dahart-icon.svg" />
                      </div>
                      <div class="ml-3">
                        <div class="text-color-white">
                          Anzahl der Stockwerke:
                        </div>
                        <div class="text-color-orange">2 Etagen</div>
                      </div>
                    </v-row>
                    <v-row no-gutters align="center" class="mt-3" v-else>
                      <div>
                        <img src="@/assets/images/dahart-icon.svg" />
                      </div>
                      <div class="ml-3">
                        <div class="text-color-white">
                          Anzahl der Schlafzimmer:
                        </div>
                        <div class="text-color-orange">{{ project.bedrooms }}</div>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <MovedArrow />
          </v-row>
        </v-container>
      </div>
    </div>
    <Header />
  </div>
</template>

<script>
import Header from "../UI/Header.vue";
import MovedArrow from "../UI/MovedArrow.vue";

export default {
  components: {
    Header,
    MovedArrow,
  },
  props: {
    project: {
      require: true,
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.info-card {
  height: 146px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  border-radius: 10px;
  width: 100% !important;
  &-mobile {
    height: 320px !important;
    width: 100% !important;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    border-radius: 10px;
  }
}
.background-main {
  position: relative;
  .image {
    width: 100vw !important;
    height: 100vh !important;
    object-fit: fill !important;
  }
  .image::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
  .content {
    position: absolute;
    top: 104px;
    left: 0;
    right: 0;
    bottom: 0px;
    color: #fff;
    &-row {
      height: calc(100vh - 104px) !important;
      &-mobile {
        height: calc(100vh - 204px) !important;
      }
    }
  }
}
</style>