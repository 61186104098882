<template>
  <div v-if="project.id">
    <MainInfoBlock :project="project" />
    <GalleryBlock :project="project" />
    <TechnologiesBlock />
    <DescriptionBlock :project="project" />
    <OthersProjectsBlock ref="others" />
    <ContactsBlock />
  </div>
</template>

<script>
import MainInfoBlock from "./MainInfoBlock.vue";
import GalleryBlock from "./GalleryBlock.vue";
import OthersProjectsBlock from "./OtherProjectsBlock.vue";
import TechnologiesBlock from "./TechnologiesBlock.vue";
import DescriptionBlock from "./DescriptionBlock.vue";
import ContactsBlock from "../UI/ContactsBlock.vue";
import ProjectsMixin from "@/mixins/ProjectsMixin.js";

export default {
  components: {
    MainInfoBlock,
    GalleryBlock,
    OthersProjectsBlock,
    TechnologiesBlock,
    DescriptionBlock,
    ContactsBlock,
  },
  data: () => ({
    project: {},
  }),
  mixins: [ProjectsMixin],
  mounted() {
    this.project = this.projects.find((el) => el.id == this.$route.params.id);
    console.log(this.project);
    window.scrollTo(0, 0);
  },
  watch: {
    "$route.path": {
      deep: true,
      handler() {
        this.project = {}
        this.project = this.projects.find(
          (el) => el.id == this.$route.params.id
        );
        this.$refs.others.randomizeElemnts(6)
        window.scrollTo(0, 0);
      },
    },
  },
};
</script>