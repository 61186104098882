<template>
    <ProjectComponent />
</template>

<script>
import ProjectComponent from '@/components/Project/ProjectComponent.vue'

export default {
    components: {
        ProjectComponent
    }
}
</script>