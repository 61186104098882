<template>
  <div class="py-15 mb-2">
    <v-container class="px-0 px-sm-auto">
      <v-row>
        <v-col class="col-6 ml-7 ml-sm-0">
          <v-row
            class="text-color-black font-36 align-center font-weight-600 position-relative title-row"
          >
            <div class="f-title">Ähnliche Projekte</div>
            <div class="sec-title">Ähnliche Projekte</div>
          </v-row>
        </v-col>
      </v-row>
      <div class="mx-n6">
        <div>
          <swiper
            :style="{
              '--swiper-navigation-color': 'transparent',
            }"
            :options="swiperOption"
            class="d-none d-md-block wrapper-slider-swiper"
            v-if="otherProjects.length"
          >
            <swiper-slide
              v-for="(project, index) in otherProjects"
              :key="index"
            >
              <div class="mx-2">
                <div class="wrapper-images">
                  <div class="anim-image">
                    <img
                      :src="require('@/assets/images/projects/' + project.folder + '/' + project.images[0])"
                    />
                  </div>
                  <figcaption class="d-context text-color-white font-24">
                    <div class="id-num">0{{ index + 1 }}</div>
                    <div class="text-uppercase">{{ project.title }}</div>
                    <v-divider color="#F39300" class="line mt-2" />
                  </figcaption>
                  <figcaption class="h-content">
                    <v-row no-gutters class="mt-3 align-center">
                      <div>
                        <img src="@/assets/images/gebiet-icon.svg" />
                      </div>
                      <div class="ml-3">
                        <div class="text-color-white">Gebiet:</div>
                        <div class="text-color-orange">
                          {{ project.square }} m<sup>2</sup>
                        </div>
                      </div>
                    </v-row>
                    <v-row no-gutters class="mt-4 align-center">
                      <div>
                        <img src="@/assets/images/kapazitat-icon.svg" />
                      </div>
                      <div class="ml-3">
                        <div class="text-color-white">Kapazität:</div>
                        <div class="text-color-orange">
                          {{ project.capacity }}
                        </div>
                      </div>
                    </v-row>
                  </figcaption>
                  <figcaption class="h-btn">
                    <v-btn
                      @click.native="toProject(project)"
                      class="btn-anim text-transform-none"
                      outlined
                    >
                      <div class="icon">
                        <v-icon color="#f2f2f2">mdi-arrow-right</v-icon>
                      </div>
                      <div class="content-text">Mehr Lesen</div>
                    </v-btn>
                  </figcaption>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev ml-1" slot="button-prev"></div>
            <div class="swiper-button-next mr-1" slot="button-next"></div>
          </swiper>
        </div>
      </div>
      <div class="d-block d-md-none">
        <div class="wrapper-slider-swiper mt-8">
          <swiper
            :style="{
              '--swiper-navigation-color': 'transparent',
            }"
            :options="swiperOptionMobile"
            class="d-block d-md-none"
            v-if="otherProjects.length"
          >
            <swiper-slide
              v-for="(project, index) in otherProjects"
              :key="index"
              class="wrapper-images-swiper col-6 pa-0"
            >
              <div class="">
                <div class="anim-image-swiper">
                  <img
                    :src="
                      require('@/assets/images/projects/' +
                        project.folder +
                        '/' +
                        project.images[0])
                    "
                  />
                </div>
                <figcaption class="d-context-swiper text-color-white font-24">
                  <div class="id-num-swiper">0{{ index + 1 }}</div>
                  <div class="text-uppercase">{{ project.title }}</div>
                  <v-divider color="#F39300" class="line-swiper mt-2" />
                </figcaption>
                <figcaption class="h-content-swiper">
                  <v-row no-gutters class="mt-3 align-center">
                    <div>
                      <img src="@/assets/images/gebiet-icon.svg" />
                    </div>
                    <div class="ml-3">
                      <div class="text-color-white">Gebiet:</div>
                      <div class="text-color-orange">
                        {{ project.square }} m<sup>2</sup>
                      </div>
                    </div>
                  </v-row>
                  <v-row no-gutters class="mt-4 align-center">
                    <div>
                      <img src="@/assets/images/kapazitat-icon.svg" />
                    </div>
                    <div class="ml-3">
                      <div class="text-color-white">Kapazität:</div>
                      <div class="text-color-orange">
                        {{ project.capacity }}
                      </div>
                    </div>
                  </v-row>
                </figcaption>
                <div class="h-btn-swiper">
                  <v-btn
                    @click="toProject(project)"
                    class="btn-anim-swiper text-transform-none"
                    outlined
                  >
                    <div class="icon-swiper">
                      <v-icon color="#f2f2f2">mdi-arrow-right</v-icon>
                    </div>
                    <div class="content-text-swiper">Mehr Lesen</div>
                  </v-btn>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
      <v-row justify="center" class="mt-8">
        <v-btn
          @click="$router.push({ name: 'all' })"
          class="btn-anim-to-all text-transform-none"
          outlined
        >
          <div class="icon">
            <v-icon color="#f2f2f2">mdi-arrow-right</v-icon>
          </div>
          <div class="content-text">Alle Projekte</div>
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import ProjectsMixin from "@/mixins/ProjectsMixin";

export default {
  mixins: [ProjectsMixin],
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    otherProjects: [],
    swiperOptionMobile: {
      slidesPerView: 2,
      spaceBetween: 0,
      slidesPerGroup: 2,
      initialSlide: 0,
      loop: true,
      loopFillGroupWithBlank: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
    swiperOption: {
      slidesPerView: 3,
      spaceBetween: 0,
      slidesPerGroup: 3,
      initialSlide: 0,
      loop: true,
      loopFillGroupWithBlank: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
  }),
  mounted() {
    this.randomizeElemnts(6);
    console.log(this.otherProjects);
  },
  methods: {
    toProject(project) {
      this.$router.push({name: 'project', params: {id: project.id}})
    },
    randomizeElemnts(count) {
      const result = [];
      const guardian = [Number(this.$route.params.id)];
      let index = 0
      let element = {}
      while (result.length < count) {
        console.log('i')
        index = Math.floor(this.projects.length * Math.random());
        if (guardian.includes(this.projects[index].id)) {
          continue;
        }
        element = this.projects[index];
        guardian.push(this.projects[index].id);
        result.push(element);
        console.log(result)
      }
      this.otherProjects = result;
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper-images {
  position: relative !important;
  overflow: hidden;
  height: 350px;
  width: 100%;
  &:hover .h-content {
    left: 20px;
  }
  &:hover .line {
    width: 100px !important;
    border: 0.5px solid #f39300;
  }
  &:hover .id-num {
    color: #f39300;
  }
  &:hover .anim-image {
    scale: 1.2;
    &::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    }
  }
  &:hover .h-btn {
    bottom: 20px;
  }
}
.anim-image {
  padding-bottom: 25% !important;
  width: 100% !important;
  object-fit: cover;
  transition: all 0.3s ease-out;
  img {
    height: 350px;
    width: 100% !important;
    object-fit: cover;
  }
  &::after {
    position: absolute;
    content: "";
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-out;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
}
.d-context {
  position: absolute;
  z-index: 3;
  top: 30px;
  left: 20px;
  .line {
    width: 0px;
    transition: all 0.3s ease-out;
  }
  .id-num {
    transition: all 0.3s ease-out;
  }
}
.h-content {
  position: absolute;
  top: 120px;
  z-index: 3;
  left: -200px;
  transition: all 0.3s ease-out;
}
.h-btn {
  position: absolute;
  z-index: 4;
  bottom: -60px;
  left: calc(50% - 96px);
  transition: all 0.3s ease-out;
}
.btn-anim {
  width: 192px;
  height: 48px !important;
  background: rgba(242, 242, 242, 0.1);
  border: 2px solid #f2f2f2;
  border-radius: 5px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #f2f2f2;
  overflow: hidden;
  transition: all 0.2s ease-out;

  .icon {
    margin-left: -80px;
    transition: all 0.2s ease-out 0s;
  }
  .content-text {
    margin-left: 50px;
    transition: all 0.2s ease-out 0.1s;
  }
  &:hover {
    background: linear-gradient(180deg, #1b1b1b 0%, #1b1b1b 200%), #f2f2f2;
    border: 2px solid #1b1b1b;
  }
  &:hover .icon {
    margin-left: 165px;
    transition: all 0.2s ease-out 0.1s;
  }
  &:hover .content-text {
    margin-top: 100px;
    transition: all 0.2s ease-out 0s;
  }
}

.wrapper-images-swiper {
  position: relative !important;
  overflow: hidden;
  height: 400px;
  &:hover .h-content-swiper {
    left: 20px;
  }
  &:hover .line-swiper {
    width: 100px !important;
    border: 0.5px solid #f39300;
  }
  &:hover .id-num-swiper {
    color: #f39300;
  }
  &:hover .anim-image-swiper {
    scale: 1.2;
    &::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    }
  }
  &:hover .h-btn-swiper {
    bottom: 70px;
  }
}

.wrapper-slider-swiper {
  &:hover .swiper-button-prev {
    opacity: 0.4;
  }
  &:hover .swiper-button-next {
    opacity: 0.4;
  }
}
.anim-image-swiper {
  height: 400px !important;
  width: 100% !important;
  object-fit: cover;
  transition: all 0.3s ease-out;
  img {
    height: 400px !important;
    width: 100% !important;
    object-fit: cover;
  }
  &::after {
    position: absolute;
    content: "";
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-out;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
}
.d-context-swiper {
  position: absolute;
  z-index: 3;
  top: 30px;
  left: 20px;
  .line-swiper {
    width: 0px;
    transition: all 0.3s ease-out;
  }
  .id-num-swiper {
    transition: all 0.3s ease-out;
  }
}
.h-content-swiper {
  position: absolute;
  top: 120px;
  z-index: 3;
  left: -200px;
  transition: all 0.3s ease-out;
}
.h-btn-swiper {
  position: absolute;
  bottom: -60px;
  z-index: 3;
  left: calc(50% - 96px);
  transition: all 0.3s ease-out;
}
.btn-anim-swiper {
  width: 152px;
  height: 48px !important;
  background: rgba(242, 242, 242, 0.1);
  border: 2px solid #f2f2f2;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #f2f2f2;
  overflow: hidden;
  transition: all 0.2s ease-out;

  .icon-swiper {
    margin-left: -90px;
    transition: all 0.2s ease-out 0s;
  }
  .content-text-swiper {
    margin-left: 60px;
    transition: all 0.2s ease-out 0.1s;
  }
  &:hover {
    background: linear-gradient(180deg, #1b1b1b 0%, #1b1b1b 200%), #f2f2f2;
    border: 2px solid #1b1b1b;
  }
  &:hover .icon-swiper {
    margin-left: 155px;
    transition: all 0.2s ease-out 0.1s;
  }
  &:hover .content-text-swiper {
    margin-top: 100px;
    transition: all 0.2s ease-out 0s;
  }
}

.swiper-button-prev {
  width: 40px;
  height: 40px;
  background: rgba(217, 217, 217, 0.15);
  border: 2px solid #efefef;
  backdrop-filter: blur(2px);
  border-radius: 1px;
  background-image: url("../../assets/images/swiper-arrow-left.svg") !important;
  background-repeat: no-repeat;
  background-size: 70% 70%;
  background-position: center;
  opacity: 0;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1 !important;
  }
}

.swiper-button-next {
  width: 40px;
  height: 40px;
  background: rgba(217, 217, 217, 0.15);
  border: 2px solid #efefef;
  backdrop-filter: blur(2px);
  border-radius: 1px;
  background-image: url("../../assets/images/swiper-arrow-right.svg") !important;
  background-repeat: no-repeat;
  background-size: 70% 70%;
  background-position: center;
  opacity: 0;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1 !important;
  }
}

.btn-anim-to-all {
  width: 260px !important;
  height: 48px !important;
  background: #f2f2f2;
  border: 2px solid #1b1b1b;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #1b1b1b;
  overflow: hidden;
  transition: all 0.2s ease-out;

  .icon {
    margin-left: -60px;
    transition: all 0.2s ease-out 0s;
  }
  .content-text {
    margin-left: 30px;
    transition: all 0.2s ease-out 0.1s;
  }
  &:hover {
    background: #1b1b1b;
  }
  &:hover .icon {
    margin-left: 165px;
    transition: all 0.2s ease-out 0.1s;
  }
  &:hover .content-text {
    margin-top: 100px;
    transition: all 0.2s ease-out 0s;
  }
}
</style>